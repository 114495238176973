require('offline-plugin/runtime').install();

// Fonts
import './fonts/FivoSans-Regular.otf';
import './fonts/ObjectSans-Heavy.otf';
import './fonts/ObjectSans-Regular.otf';

// HTML Files
import './index.html';
import './about.html';
import './ca-next-bank.html';
import './anonymous.html';
import './disneyland.html';
import './white-paper.html';

// SCSS & JS
import './index.scss';
import './scripts/script.js';

// Images
import './images/opengraph.jpg';
import './images/loading.mp4';
import './images/transition-ca.png';
import './images/transition-anonymous.png';
import './images/transition-disney.png';
import './images/transition-ecv.png';
import './images/dechirure.svg';
import './images/Disneyland/disney-item.png';
import './images/Anonymous/anonymous-item.png';
import './images/CA/CA-item.png';
import './images/ECV/ECV-item.png';
import './images/Anonymous/Anonymous-top.png';
import './images/Anonymous/Anonymous-bottom.png';
// import './images/Anonymous/Anonymous_poster.png';
import './images/Anonymous/Anonymous_poster-bottom.png';
import './images/Anonymous/Anonymous-01.png';
import './images/Anonymous/Anonymous-02.png';
import './images/CA/CA-top.png';
import './images/CA/CA-bottom.png';
// import './images/CA/CA_poster.png';
import './images/CA/CA-01.png';
import './images/CA/CA-02.png';
import './images/CA/CA-03.png';
import './images/Disneyland/Disneyland-top.png';
import './images/Disneyland/Disneyland-bottom.png';
import './images/Disneyland/Disneyland_poster-bottom.png';
// import './images/Disneyland/Disneyland_poster.png';
import './images/Disneyland/Disneyland-01.png';
import './images/Disneyland/Disneyland-02.png';
import './images/Disneyland/Disneyland-03.png';
import './images/Disneyland/Disneyland-04.png';
import './images/ECV/ECV-top.png';
import './images/ECV/ECV-bottom.png';
// import './images/ECV/ECV_poster.png';
import './images/ECV/ECV_poster-bottom.png';
import './images/ECV/ECV-fullscreen.png';
import './images/ECV/ECV-01.png';
import './images/ECV/ECV-02.png';
import './images/ECV/ECV-03.png';
import './images/arrow.svg';
import './images/profile.png';
import './images/about/trump.png';
import './images/about/joker.png';
import './images/about/hagrid.png';